import { CreateBot } from "@/types";
import { API } from "@/utils/api";
import { stringify } from "query-string";

export const getBotTemplates = async () => {
  const response = await API().get(`/bot_templates`);
  return response.data.data.botTemplates;
};

// Note: no longer used.
export const getOneBotTemplate = async (botTemplateId: string) => {
  const response = await API().get(`/bot_template/${botTemplateId}`);
  return response.data.data.botTemplate;
};

export const createBot = async (payload: CreateBot) => {
  const response = await API().post(`/bot`, payload);
  return response.data.data.bot;
};

export const updateBotById = async (payload: CreateBot) => {
  const response = await API().put(`/bot/${payload.id}`, payload);
  return response.data;
};

export const getAllBots = async (
  objectId = "",
  queryBy = "userId", // userId, botId, projectId, addressId
  sortBy = "time", // name, time, type, severity
  sortOrder = -1, // 1 for ascending, -1 for descending
  typeFilter = "", // SmartThreatMonitor, AbnormalTransferSol, ....
  severityFilter = "", // low, medium, high, critical
  offset = 0,
  size = 10,
) => {
  const response = await API().get(
    `/bots?id=${objectId}&queryBy=${queryBy}&sortBy=${sortBy}&sortOrder=${sortOrder}&offset=${offset}&size=${size}&typeFilter=${typeFilter}&severityFilter=${severityFilter}`,
  );
  return response.data.data;
};

export const getLeanBots = async (
  objectId = "",
  queryBy = "userId", // userId, botId, projectId, addressId
  typeFilter = "", // SmartThreatMonitor, AbnormalTransferSol, ....
  severityFilter = "", // low, medium, high, critical
) => {
  const response = await API().get(
    `/lean_bots?${stringify({
      id: objectId,
      queryBy,
      typeFilter,
      severityFilter,
    })}`,
  );
  return response.data.data;
};

export const getBotById = async (botId: string) => {
  const response = await API().get(`/bot/${botId}`);
  return response.data.data.bot;
};

export const startBotById = async (botId: string) => {
  const response = await API().post(`/bot/${botId}/_start`);
  return response.data;
};

export const stopBotById = async (botId: string) => {
  const response = await API().post(`/bot/${botId}/_stop`);
  return response.data;
};

export const deleteBotById = async (botId: string) => {
  const response = await API().delete(`/bot/${botId}`);
  return response.data;
};
